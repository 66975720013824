type Settings = {
  seatMap: string;
  cmsDataset: string;
  busForTrainUrl: string;
  opsTrainCardUrl: string;
  goOpsApi: string;
  deviationConnectionUrl: string;
  rtvConnectionUrl: string;
};

export const Config = (): Settings => {
  switch (window && window.location.origin) {
    case 'http://host.docker.internal:8080':
    case 'http://127.0.0.1:8080':
    case 'http://127.0.0.1':
    case 'https://testdockerimage.azurewebsites.net':
      return {
        seatMap: 'https://test.goalive.go-aheadnordic.no/rest/SeatMap/resources',
        cmsDataset: 'development',
        busForTrainUrl: 'https://trafikk-dev.gan.no/avvik/',
        opsTrainCardUrl: 'https://trafikk-dev.gan.no/train-card/',
        goOpsApi: 'https://api-dev.gan.no/api/gan-web',
        deviationConnectionUrl: 'https://api-dev.gan.no/dev/gan-web-critical-deviations',
        rtvConnectionUrl: 'https://api-dev.gan.no/dev/gan-web-rtv-trains-hub',
      };
    case 'https://test.goalive.go-aheadnordic.no':
      return {
        seatMap: `${window.location.origin}/rest/SeatMap/resources`,
        cmsDataset: 'development',
        busForTrainUrl: 'https://trafikk-dev.gan.no/avvik/',
        opsTrainCardUrl: 'https://trafikk-dev.gan.no/train-card/',
        goOpsApi: 'https://api-dev.gan.no/api/gan-web',
        deviationConnectionUrl: `https://api-dev.gan.no/dev/gan-web-critical-deviations`,
        rtvConnectionUrl: 'https://api-dev.gan.no/dev/gan-web-rtv-trains-hub',
      };
    case 'https://goaliveqa.api.go-aheadnordic.no':
    case 'https://qa.go-ahead.no':
    case 'https://qa.go-aheadnordic.no':
      return {
        seatMap: `${window.location.origin}/rest/SeatMap/resources`,
        cmsDataset: 'production',
        busForTrainUrl: 'https://trafikk-stage.gan.no/avvik/',
        opsTrainCardUrl: 'https://trafikk-stage.gan.no/train-card/',
        goOpsApi: 'https://api-stage.gan.no/api/gan-web',
        deviationConnectionUrl: `https://api-stage.gan.no/stage/gan-web-critical-deviations`,
        rtvConnectionUrl: 'https://api-stage.gan.no/stage/gan-web-rtv-trains-hub',
      };
    case 'https://billetter.go-aheadnordic.no':
    case 'https://go-aheadnordic.no':
    case 'https://go-ahead.no':
    case 'https://gan.no':
      return {
        seatMap: `${window.location.origin}/rest/SeatMap/resources`,
        cmsDataset: 'production',
        busForTrainUrl: 'https://trafikk.gan.no/avvik/',
        opsTrainCardUrl: 'https://trafikk.gan.no/train-card/',
        goOpsApi: 'https://api.gan.no/api/gan-web',
        deviationConnectionUrl: `https://api.gan.no/prod/gan-web-critical-deviations`,
        rtvConnectionUrl: 'https://api.gan.no/prod/gan-web-rtv-trains-hub',
      };
    default:
      return {
        seatMap: 'https://go-ahead.no/rest/SeatMap/resources',
        cmsDataset: 'production',
        busForTrainUrl: 'https://trafikk.gan.no/avvik/',
        opsTrainCardUrl: 'https://trafikk.gan.no/train-card/',
        goOpsApi: 'https://api.gan.no/api/gan-web',
        deviationConnectionUrl: 'https://api.gan.no/prod/gan-web-critical-deviations',
        rtvConnectionUrl: 'https://api.gan.no/prod/gan-web-rtv-trains-hub',
      };
  }
};
