import React from 'react';
import styled from 'styled-components';
import { useI18n } from 'utils/i18n/usei18n';
import {
  TxtLargeMediumDarkResp,
  TxtDefaultDarkGreyResp,
  TxtLargeBoldLightResp,
  TxtDefaultMediumDarkResp,
} from 'elements/new-design/Typography';
import { TxtLinkDefaultBrown } from 'elements/links/Links';
import { Config } from 'utils/Config';
import { FlexBetween, FlexBetweenFullWidth, FlexColumn, FlexEnd } from 'elements/containers/Containers';
import { BusForTrain } from './BusForTrain';
import { getTrainLineFromProducLineId } from 'utils/Helpers';
import { MarginRight } from 'elements/distance/Margins';

type CriticalDeviationProps = {
  deviation: any;
};

type StyleProps = {
  isCritical: boolean;
};

type TrainLineProps = {
  trainLine: string;
};

const DeviationContainer = styled.div`
  ${(_: StyleProps) => ''}
  margin-bottom: 2.4rem;
  box-shadow: ${(props) => props.theme.newcolors.borderBoxshadow};
  border-radius: 2rem;
`;

const TrainLine = styled.div`
  ${(_: TrainLineProps) => ''}
  border-top-left-radius: 2rem;
  background-color: ${(props) =>
    props.trainLine === 'F5'
      ? props.theme.newcolors.bgBlue
      : props.trainLine === 'R50'
      ? props.theme.newcolors.bgRed
      : props.theme.newcolors.bgGreen};
  padding: 0.5rem 1.5rem;
  align-self: flex-start;
`;

const FromTo = styled(FlexBetweenFullWidth)`
  padding: 0.5rem 1.5rem;
`;

const PlannedOngoingHeader = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.newcolors.bgLightTeal};
  border-top-left-radius: 2rem;
  border-top-right-radius: 2rem;
`;

const PlannedOngoingTopHeader = styled(FlexBetween)`
  @media ${(props) => props.theme.breakpoints.xsmall} {
    flex-direction: column;
  }
`;

const DeviationText = styled(TxtDefaultMediumDarkResp)`
  display: flex;
  padding: 1rem 1.5rem;
  align-self: flex-start;
`;

const ReadMore = styled(TxtLinkDefaultBrown)`
  display: flex;
  justify-content: end;
  padding: 0.5rem 1.5rem;
`;

const getDaysShort = (weekDays: string[]) =>
  weekDays.reduce((acc, curr) => {
    acc.push(curr.substring(0, 3));
    return acc;
  }, []);

const capitalizeFirstLetter = (stringToCapitalize: string) =>
  stringToCapitalize.charAt(0).toUpperCase() + stringToCapitalize.slice(1);

export const PlannedOngoingDeviation = ({ deviation }: CriticalDeviationProps): JSX.Element => {
  const { currentLanguage, translate } = useI18n();
  const BUS_FOR_TRAIN_URL = Config().busForTrainUrl;

  const handleDays = () => {
    if (deviation.weekDays.no.length === 1) {
      return currentLanguage === 'no'
        ? `${translate('ONLY_VALID')} ${deviation.weekDays.no.join(', ').toLowerCase()}`
        : `${translate('ONLY_VALID')} ${capitalizeFirstLetter(deviation.weekDays.en.join(', '))}`;
    } else
      return currentLanguage === 'no'
        ? capitalizeFirstLetter(getDaysShort(deviation.weekDays.no).join(', ').toLowerCase())
        : capitalizeFirstLetter(getDaysShort(deviation.weekDays.en).join(', '));
  };

  return (
    <DeviationContainer isCritical={deviation.messageAlert}>
      <PlannedOngoingHeader>
        <PlannedOngoingTopHeader>
          <TrainLine trainLine={getTrainLineFromProducLineId(deviation.affectedProductLineIds[0])}>
            <TxtLargeBoldLightResp>
              {getTrainLineFromProducLineId(deviation.affectedProductLineIds[0])}
            </TxtLargeBoldLightResp>
          </TrainLine>

          <FlexColumn>
            <TxtDefaultDarkGreyResp>{`${capitalizeFirstLetter(
              deviation.fromDateTime.formatDateFormatShort(currentLanguage),
            )} - ${deviation.untilDateTime.formatDateFormatShort(currentLanguage)}`}</TxtDefaultDarkGreyResp>
            {deviation.weekDays && <TxtDefaultDarkGreyResp>{`${handleDays()}.`}</TxtDefaultDarkGreyResp>}
            {deviation.withDailyTimeSlot && (
              <TxtDefaultDarkGreyResp>{`${translate(
                'BETWEEN',
              )} ${deviation.fromDateTime.formatTime()} - ${deviation.untilDateTime.formatTime()}`}</TxtDefaultDarkGreyResp>
            )}
          </FlexColumn>
          <FlexEnd>
            <MarginRight margin={1.5}>
              <BusForTrain deviationId={deviation.situationId} />
            </MarginRight>
          </FlexEnd>
        </PlannedOngoingTopHeader>

        <FromTo>
          <TxtLargeMediumDarkResp>{deviation.stationFrom}</TxtLargeMediumDarkResp>
          <TxtLargeMediumDarkResp>{deviation.stationTo}</TxtLargeMediumDarkResp>
        </FromTo>
      </PlannedOngoingHeader>

      <DeviationText>
        {currentLanguage === 'no' ? deviation.messageDescription.no : deviation.messageDescription.en}
      </DeviationText>

      <ReadMore href={`${BUS_FOR_TRAIN_URL}${deviation.situationId}`} target="_blank" rel="noreferrer">
        {translate('READ_MORE')}
      </ReadMore>
    </DeviationContainer>
  );
};
